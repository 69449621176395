export const allowlistAddresses = [
   ["0x5964e73355f9c44e01175bd868da4c81aa018b41", 3],
   ["0x6b61cd47b4563a2016caa8d8b09e95d4f3296e50", 3],
   ["0x882d23171f35e9620ddec45b54f56456f85fc613", 3],
   ["0xb4026b6a3a267436b4eb65ae3a0d9724eb6f942a", 3],
   ["0x5ced3942cf01c4d9bbdd189f5f7edf9789126f41", 3],
   ["0x092fcf216095a4cb7ac616bd30b26c4141b39019", 3],
   ["0x2b213ba1f178c08b9fa90b02b0e1d6bdfc9f450a", 3],
   ["0x5b15387951101e39f8438e2e1fca39f581a97ae4", 3],
   ["0xcbd9c59765fb3b5871ba69086b0f116e9016c825", 3],
   ["0x81a7c8f396a6b599856ec7132343f3ff36a69b23", 3],
   ["0xd2f18ae6dd74ecac828bef77f95cbf42d0d3bd6e", 3],
   ["0x6ac513e879a9d8ce20dbf11d6fff3de0d4654908", 3],
   ["0x36c383b8a18a299761f4a073a8802729ff62965d", 3],
   ["0x0a58ea5fcefecef9f3ce967e16d1c74dc71d0a54", 3],
   ["0xb6efd299147a277a5b9bd4d8ecd94e1b141922b1", 3],
   ["0xad29f6dd5a03105813ad0d879383f818c6b5fb99", 3],
   ["0x5a15bc3a571144af4395496bdbb6a137e5adc174", 3],
   ["0xb3870ae0c7052ab60c045bd331249dc12e9ad881", 3],
   ["0x9f3a6df7bc869b77fe3e5e050f305d9835d9192d", 3],
   ["0xa906b467dfcd7b18d72a97283798539cf0ffa66d", 3],
   ["0xb354ab49054d789996181f840b8ae47777b40111", 3],
   ["0xfecfefc100ae961359e23b9d40b6a7f5f9931f4b", 3],
   ["0x0c7d841966696af4d1aff9a56424ab938626a443", 3],
   ["0x2339b94dd0d1d6211ed14e2ec817fe0819d41b18", 3],
   ["0xca689c3234de16b8f5c5a7f94e740ae902ec7e6f", 3],
   ["0x7696ec0e7924ac4b40f6893a68c0f26b88ca7b58", 3],
   ["0x365c3e51bdc17f6c8b69073b9d11aa3d297a5ac8", 3],
   ["0xdf86fdd7cbe4bed371e5278d25e3a3ee5d8f56e7", 3],
   ["0xe1242abc540075440e09070c36a1ce2b8a1aafef", 3],
   ["0xd4f2a017e8ae0ac79fd4480855a086d478b73a89", 3],
   ["0xb874a00db7e72856b4f3fed3fc6ebbfc14e864bc", 3],
   ["0xe06223f8610a0c7631204e36581a3dcff77a4889", 3],
   ["0x3fbdcab3a1bb292393f5f91f713f852ccb73e52e", 3],
   ["0x374930e5b84c92b60f033349e34b87e29449062e", 3],
   ["0xe6ba46cc70f2172b77b08c51ea8bb1cb732010c1", 3],
   ["0xeafb25cf3ca2e26e2ffbb825f2f50b10f7f32605", 3],
   ["0x578499dc47a8b4fc910fa7cb333965056413ab61", 3],
   ["0x4195d0d269b41425f06f5d1e2b1c70c1bd339cf1", 3],
   ["0xe6eaf29c78590eec67b421d2136655458f5a8675", 3],
   ["0x6d0b7e7e27f023b9f281291386c2e307cd366042", 3],
   ["0x10ba08973dedb917ac64f37d681922b69f48a7ef", 3],
   ["0x2e1a2f713526ba740350e4664b6577173d9113fc", 3],
   ["0xfcd49aa97b91c3575d36c95a1fe97dabec017890", 3],
   ["0x236a21e7ce1490022598379bdd3ff6c627a71b23", 3],
   ["0xc76730801ee484a3669fa3f4de5ee26ad04b06e2", 3],
   ["0x5b61525169ab8384d11dce5ef75223a9bdea1769", 3],
   ["0xe19216644ae5e188daabe8c9fc515ec4783d52cc", 3],
   ["0x89d0b705d2d976778114fe875e5766643b831894", 3],
   ["0x6bcec25aa22c9216e9238d7a0bdc234c57d1fcdf", 3],
   ["0x1002e8efdfa14a82370f4767e427b3d4a3ff13b1", 3],
   ["0x1e8a5a4fbf0edce6d2b0925a14682c5bdcb369d7", 3],
   ["0xa0d8ddc7ec2249c6df227db8e6da21b6efee7f58", 3],
   ["0x089e50bd2ccde2f926c26ac0cea4d11b29e0bc33", 3],
   ["0xc63aff1a8cc97ea4f9823e287dfbc53f2db24d49", 3],
   ["0x811b68c83d0fa67aa34872fc2a5d380e5a314baf", 3],
   ["0xfcd49aa97b91c3575d36c95a1fe97dabec017890", 3],
   ["0xd9bb2ed5903c08eb14fc99a3f6c67985f6cc7839", 3],
   ["0xca61b9abfdbb72a119c8585090259a28da6f797f", 3],
   ["0x9de62c4476f07003547edf75330bf30303cad90f", 3],
   ["0xae6c486c3aac0d267a31f1b522081feeaa9a9451", 3],
   ["0xed8b3b6fe916acdbb08eecbeae80a1701d1267f2", 3],
   ["0x5ab7e4fdb48138bfa7bef7a097d9c2157f6b21e8", 3],
   ["0x86eb8be678274ba4c47af17fa8511c3f9c631bbc", 3],
   ["0x19024dfc106e6111f80225a491b045aa6d8b9941", 3],
   ["0xb73e2d3fed902a20f3feb7b05fc589bd7dee023c", 3],
   ["0x0006c7dc8e482a4e8007343d821b7932856ccdaa", 3],
   ["0x483b606bb4f2d3c549d07ff49cdd4c435f125a96", 3],
   ["0xfbbe0f22abecf94bbf1d7646544750fe31ee7021", 3],
   ["0x9a1fee8b2b576f198f75493aa8548db879e7fbb6", 3],
   ["0x91d64f967c0c61949f8e226032275c7246919d0b", 3],
   ["0x57b977465b9e8e39bfa54e0b4bc0a66d7099684c", 3],
   ["0xe9185bca12677bf25100a14d90a539fa6b2b2a09", 3],
   ["0x7d8f441bf65ff8cda99059fb5137af720de562fb", 3],
]